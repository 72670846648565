<template>
  <v-container style="max-width: 600px; height: 100%" class="pa-6 pt-16">
    <div class="search">
      <div class="search-input">
        <v-text-field
          v-model="searchInput"
          solo
          label="商品を検索する"
          @keyup.enter="handleSearch"
          clearable
          append-icon="mdi-arrow-left-bottom-bold"
          @click:append="handleSearch"
        ></v-text-field>
      </div>
    </div>
    <div class="mt-8"></div>
    <v-row v-if="showProducts.length">
      <v-col
        style="position: relative"
        cols="6"
        v-for="product in showProducts"
        :key="product._id"
        class="px-1"
      >
        <div v-if="product.is_soldout" class="is-sold-out">
          <div>品切れ</div>
        </div>
        <v-card @click="clicked(product._id)" elevation="0">
          <v-img
            class="white--text align-end ma-auto"
            :src="product.thumbnail"
            aspect-ratio="1"
            style="object-fit: cover; border-radius: 10px"
            gradient="0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.11) 40%, rgba(0, 0, 0, 0) 86%"
          >
            <div
              class="text-caption ml-1 mb-1 font-weight-bold line-clamp"
              v-html="product.subtitle"
            ></div>
          </v-img>
          <v-card-text class="text-caption pa-0 pl-2 pt-1">
            <div class="text-truncate">
              <span>{{ product.brand }}</span>
            </div>

            <div class="black--text font-weight-bold text-body-2 text-truncate">
              {{ product.title }}
            </div>
            <div class="mx-1 my-1 black--text">
              <div v-if="product.is_onsale" class="pt-2 text-body-1 deep-orange--text">
                <div class="font-weight-bold">
                  <span class="text-decoration-line-through">{{ product.price }}</span>
                  <span>{{ ` ${Math.floor(product.sale_price || 0)} ` }}</span>
                  円
                </div>
                <div class="black--text text-caption" style="margin-top: -5px">
                  税込
                  <span class="text-decoration-line-through">
                    {{ Math.floor(product.price * (1 + product.tax)) }}
                  </span>
                  <span>{{ Math.floor(product.sale_price * (1 + product.tax)) }}</span>
                  円
                </div>
              </div>
              <div v-else class="pt-2 text-body-1 deep-orange--text">
                <div class="font-weight-bold">{{ product.price }}円</div>
                <div class="black--text text-caption" style="margin-top: -5px">
                  税込{{ Math.floor(product.price * (1 + product.tax)) }}円
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="flex justify-center mt-8" v-if="!searchInput">
      <v-alert>キーワードをご入力ください</v-alert>
    </v-row>
    <v-row class="flex justify-center mt-8" v-else-if="!showProducts.length">
      <v-alert>該当商品がございません</v-alert>
    </v-row>
  </v-container>
</template>

<script>
  import Methods from '@/api/method.js';

  export default {
    name: 'SearchProducts',

    data() {
      return {
        searchInput: null,
        showProducts: [],
      };
    },

    methods: {
      handleSearch: async function () {
        try {
          let response = await Methods.searchProducts({
            title_lk: this.searchInput,
            is_archived: 0,
            sortField: 'is_soldout',
            sortOrder: 1,
          });
          this.showProducts = response.data.products.map((item) => {
            let price = 100000;
            let min_sku = {};
            for (let sku of item.sku_objects) {
              if (sku.is_leaf && !sku.is_disabled) {
                if (sku.sku_product.price < price) {
                  price = sku.sku_product.price;
                  min_sku = sku;
                }
              }
            }
            return {
              ...item,
              price,
              sale_price: price * item.sale_discount,
              min_sku,
            };
          });
        } catch (error) {
          console.log(error);
        }
      },
      clicked: function (id) {
        this.$router.push('/search/product/' + id);
      },
    },
    beforeRouteLeave(to, from, next) {
      this.$route.meta.scrollHeight = document.documentElement.scrollTop;
      next();
    },
  };
</script>

<style lang="scss" scoped>
  .search {
    display: flex;
    justify-content: center;
    width: 100vw;
    position: fixed;
    top: 64px;
    left: 0;
    z-index: 1000;
  }
  .search .search-input {
    width: 100vw;
    max-width: 600px;
    padding: 6px;
  }

  .line-clamp {
    display: -webkit-box;
    display: -webkit-box !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
  }

  .is-sold-out {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: #00000021;
  }
  .is-sold-out > div {
    width: 160px;
    height: 160px;
    line-height: 160px;
    margin: 20% auto auto;
    border: 6px solid #f57c00;
    border-radius: 50%;
    transform: rotate(-30deg);
    color: #f57c00;
    font-size: 42px;
    font-weight: 900;
    text-align: center;
    text-shadow: 2px 2px 1px white;
    box-shadow: 2px 2px 1px white;
  }

  @media screen and (max-width: 600px) {
    .is-sold-out > div {
      width: 140px;
      height: 140px;
      line-height: 140px;
      margin: 10% auto auto;
      border: 6px solid #f57c00;
      border-radius: 50%;
      transform: rotate(-30deg);
      color: #f57c00;
      font-size: 36px;
    }
  }
</style>
